exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-post-index-js-content-file-path-blog-about-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/about/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-about-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-call-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/call/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-call-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-donate-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/donate/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-donate-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-goals-2023-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/goals/2023.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-goals-2023-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-portfolio-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/portfolio/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-portfolio-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-posts-deploy-services-at-a-low-cost-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/posts/deploy-services-at-a-low-cost/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-posts-deploy-services-at-a-low-cost-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-posts-deploy-to-ipfs-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/posts/deploy-to-ipfs/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-posts-deploy-to-ipfs-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-posts-e-2-ee-for-email-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/posts/e2ee-for-email/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-posts-e-2-ee-for-email-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-posts-mini-align-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/posts/mini-align/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-posts-mini-align-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-posts-webfs-explorer-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/posts/webfs-explorer/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-posts-webfs-explorer-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-zh-posts-2022-magic-xp-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/zh/posts/2022-magic-xp/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-zh-posts-2022-magic-xp-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-zh-posts-cdc-card-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/zh/posts/cdc-card/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-zh-posts-cdc-card-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-zh-posts-cny-cnh-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/zh/posts/cny-cnh/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-zh-posts-cny-cnh-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-zh-posts-e-2-ee-for-email-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/zh/posts/e2ee-for-email/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-zh-posts-e-2-ee-for-email-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-zh-posts-free-email-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/zh/posts/free-email/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-zh-posts-free-email-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-zh-posts-learning-threejs-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/zh/posts/learning-threejs/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-zh-posts-learning-threejs-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-zh-posts-singapore-payment-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/zh/posts/singapore-payment/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-zh-posts-singapore-payment-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-zh-posts-use-friz-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/zh/posts/use-friz/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-zh-posts-use-friz-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-zh-posts-why-is-yen-weak-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/zh/posts/why-is-yen-weak/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-zh-posts-why-is-yen-weak-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-zh-recommend-android-apps-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/zh/recommend/android-apps.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-zh-recommend-android-apps-mdx" */),
  "component---src-templates-post-index-js-content-file-path-blog-zh-recommend-index-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/blog/zh/recommend/index.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-blog-zh-recommend-index-mdx" */),
  "component---src-templates-post-index-js-content-file-path-src-components-donate-vendors-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/src/components/donate-vendors.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-src-components-donate-vendors-mdx" */),
  "component---src-templates-post-index-js-content-file-path-src-components-portfolio-mdx": () => import("./../../../src/templates/post/index.js?__contentFilePath=/vercel/path0/src/components/portfolio.mdx" /* webpackChunkName: "component---src-templates-post-index-js-content-file-path-src-components-portfolio-mdx" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

